<template>
  <transition :appear="true" name="slide">
    <div @touchmove.prevent class="video-detail">
      <scroll ref="scroll" class="scroll" :data="videoList">
        <div>
          <video-detail-header
            :video="currentVideo"
            :favorites-active="favoritesActive"
            @favorites="favorites"
            @share="share"
          ></video-detail-header>
          <video-detail-desc
            :video="currentVideo"
            :video-list="videoList"
            :current-index="currentIndex"
            @changeVideo="changeVideo"
            @refresh="refresh"
          ></video-detail-desc>
          <video-detail-like :like="like" @changeLike="changeLike"></video-detail-like>
          <video-detail-ranking :list="list"></video-detail-ranking>
        </div>
      </scroll>
      <overlay
        class="overlay"
        :show="showShare"
      >
        <share
          :show="showShare"
          :pic-url="currentVideo?currentVideo.media_cover:''"
          :name="currentVideo?currentVideo.media_name:''"
          :user-name="userName"
          :agency-guid="agencyGuid"
          :user-guid="userGuid"
          @shareClose="showShare=false"
        ></share>
      </overlay>
       <overlay :show="showSignFlag">
        <sign-overlay @close="showSignFlag = false"></sign-overlay>
      </overlay>
    </div>
  </transition>
</template>
<script>
import Scroll from '@/components/scroll/scroll'
import VideoDetailHeader from './components/Header'
import VideoDetailDesc from './components/Desc'
import VideoDetailLike from './components/Like'
import VideoDetailRanking from './components/Ranking'
import { getAudios, getResourcesPageList, setCUserLog } from '@/api/Resources'
import { mapGetters, mapMutations } from 'vuex'
import { appInit } from '@/utils/init'
import { Overlay, Toast } from 'vant'
import { setCUserFavorites } from '@/api/Cuser'
import Share from './components/Share'
import { collect } from '@/api/Collects'
import { setGameSec } from '@/api/Questions'
import { signIn21Day } from '@/api/SignIn'
import { getDate } from '@/utils/date'
import SignOverlay from '../common/SignOverlay'
export default {
  name: 'VideoDetail',
  computed: {
    currentVideo () {
      return this.videoList[this.currentIndex]
    },
    ...mapGetters([
      'userGuid',
      'agencyGuid',
      'userName',
      'userCityName'
    ])
  },
  components: {
    Scroll,
    VideoDetailHeader,
    VideoDetailDesc,
    VideoDetailLike,
    VideoDetailRanking,
    Overlay,
    Share,
    SignOverlay
  },
  created () {
    let query = this.$route.query
    console.log('query--', query)
  },
  mounted () {
    document.title = this.$route.params.name
    this.setPlaying(false)
    this._videoInit()
    this.gameTimer = setInterval(() => {
      if (!this.$dbfToast.$data.show) {
        collect({
          c_user_guid: this.userGuid,
          agency_guid: this.agencyGuid
        }).then(res => {
          if (res.code === 200) {
            this.$dbfToast.showToast()
          }
        })
      }
    }, 5000)
    // 答题
    this.gameTimer2 = setInterval(() => {
      if (!this.$questionPopup.$data.show) {
        setGameSec({
          c_user_guid: this.userGuid,
          agency_guid: this.agencyGuid
        }).then(res => {
          if (res.code === 200) {
            if (res.data.activity) {
              localStorage.setItem('activity', JSON.stringify(res.data.activity))
            }
            if (res.data && res.data.ok && res.data.ok === 1) {
              this.$questionPopup.showPopup()
            }
          } else {
            clearTimeout(this.gameTimer2)
          }
        })
      }
    }, 12000)
    // 签到打卡 浏览1分钟
    this.gameTimer3 = setTimeout(() => {
      console.log('看视频签到')
      signIn21Day({
        sign_in_user: this.userGuid,
        sign_in_agency: this.agencyGuid,
        sign_in_time: getDate(),
        sign_in_from: 3
      }).then(res => {
        console.log('看视频签到', res)
        const { code } = res
        if (code === 200) {
          this.showSignFlag = true
        }
      }).catch(re => {
        console.log(re)
      })
    }, 1000 * 60)// 60000
  },
  beforeDestroy () {
    clearInterval(this.gameTimer)
    clearInterval(this.gameTimer2)
  },
  data () {
    return {
      videoList: [],
      currentIndex: -1,
      favoritesActive: false,
      like: [],
      list: [],
      showShare: false,
      showSignFlag: false
    }
  },
  methods: {
    async _videoInit () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      const audioRes = await getAudios({
        resources_guid: this.$route.params.guid,
        c_user_guid: this.userGuid
      })
      if (audioRes.code === 200) {
        this.videoList = audioRes.data.list
        this.currentIndex = audioRes.data.current_index
        this.favoritesActive = this.videoList[this.currentIndex].favorites
      } else if (audioRes.code === 401) {
        appInit(this.agencyGuid, this.$route.path)
      } else if (audioRes.code === 402) {
        Toast.clear()
        this.$router.push({
          name: 'Login',
          params: {
            link: this.$route.path
          }
        })
      } else {
        return Toast.fail(audioRes.msg)
      }
      let categoryGuid = ''
      if (this.currentVideo) {
        if (this.currentVideo.category.length) {
          categoryGuid = this.currentVideo.category[0].category_guid
        }
      }
      getResourcesPageList({
        agency_guid: this.agencyGuid,
        resources_type: 3,
        page: 1,
        limit: 3,
        category_guid: categoryGuid,
        rand: true,
        not_in_guid: this.$route.params.guid
      }).then(res => {
        if (res.code === 200) {
          this.like = res.data.list
          this.refresh()
        }
      })
      getResourcesPageList({
        agency_guid: this.agencyGuid,
        resources_type: 3,
        page: 1,
        limit: 10,
        field: 'resources_read_num'
      }).then(res => {
        if (res.code === 200) {
          this.list = res.data.list
          this.refresh()
        }
      })
      this.$nextTick(() => {
        this.$refs.scroll.scrollTo(0, 0)
      })
      this.refresh()
      Toast.clear()
    },
    changeVideo (index) {
      this.currentIndex = index
    },
    favorites () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      setCUserFavorites({
        c_user_guid: this.userGuid,
        favorites_obj: this.currentVideo.resources_guid,
        favorites_type: 3,
        favorites_status: this.favoritesActive ? -1 : 1
      }).then(res => {
        if (res.code === 200) {
          this.favoritesActive = !this.favoritesActive
        }
        Toast.clear()
      })
    },
    share () {
      this.showShare = true
    },
    changeLike () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      let categoryGuid = ''
      if (this.currentVideo) {
        if (this.currentVideo.category.length) {
          categoryGuid = this.currentVideo.category[0].category_guid
        }
      }
      getResourcesPageList({
        agency_guid: this.agencyGuid,
        resources_type: 3,
        page: 1,
        limit: 3,
        category_guid: categoryGuid,
        rand: true,
        not_in_guid: this.$route.params.guid
      }).then(res => {
        if (res.code === 200) {
          this.like = res.data.list
        }
        this.refresh()
        Toast.clear()
      })
    },
    refresh () {
      setTimeout(() => {
        this.$refs.scroll.refresh()
      }, 100)
    },
    ...mapMutations({
      setPlaying: 'SET_PLAYING'
    })
  },
  watch: {
    currentIndex () {
      setCUserLog({
        resources_guid: this.currentVideo.resources_guid,
        c_user_guid: this.userGuid,
        agency_guid: this.agencyGuid,
        c_user_location: this.userCityName,
        resources_ext: this.currentVideo.media_guid,
        resources_type: 3
      })
    },
    $route (to, from) {
      if (this.$route.name === 'VideoDetail' || this.$route.name === 'BooksDetailVideoDetail' || this.$route.name === 'HomeVideoDetail' || this.$route.name === 'SignInVideoDetail') {
        document.title = this.$route.params.name
        this._videoInit()
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.slide-enter-active, .slide-leave-active
  transition: all 0.3s

.slide-enter, .slide-leave-to
  transform: translate3d(100%, 0, 0)

.video-detail
  position absolute
  z-index 100
  top 0
  left 0
  right 0
  bottom 0
  background rgba(245, 245, 244, 1)

  .scroll
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    overflow hidden

  .overlay
    z-index 200
</style>
